import React, { useState } from "react";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import { AuthContext } from "./context";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");

  const base_url = process.env.REACT_APP_API_URI;
  return (
    <AuthContext.Provider
      value={{ base_url, isLoggedIn, setIsLoggedIn, user, setUser }}
    >
      <div className="my-3">{isLoggedIn ? <Dashboard /> : <Login />}</div>
    </AuthContext.Provider>
  );
}

export default App;
